@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

@tailwind base;
@tailwind components;

.shadow {
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.15);
  }

.nav:hover{
    background: #0b8aaa;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.15);
    padding-left: 10px;
    color: #FFF
}


* {
  margin:0;
}
.no-border-row {
  border: none !important;
}

.react-data-table-component .rdt_TableRow {
  border: none !important;
}

@tailwind utilities;