.rdt_TableRow {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
    
  }

  .rdt_TableHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width:100%;
    white-space: nowrap !important; /* Prevent text from wrapping */
    overflow: visible !important; /* Allow content to overflow the cell */
    width: auto !important;

  }

  .rdt_TableCell {
    white-space: nowrap !important;
    overflow: visible !important; /* Allow content to overflow the cell */
    width: auto !important;
  }

  .rdt_TableCell {
    white-space: normal !important; /* Prevent text from wrapping */
    overflow: visible !important; /* Allow content to overflow the cell */
    width: auto !important;
  }

  .rdt_TableBody {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }